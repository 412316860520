import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { PageFooter } from "@components/page-footer";
import { HeadeSearch } from "@components/head-search";
import { PageTop } from "@components/page-top";
import { Account, AccountStorage } from "@services/account";
var account = new Account();
export default {
  components: {
    HeadeSearch: HeadeSearch,
    PageFooter: PageFooter,
    PageTop: PageTop
  },
  data: function data() {
    return {
      isLogin: false,
      payResults: 0,
      //1成功  0失败
      payStatua: this.$route.params.payType,
      orderId: this.$route.params.orderId
    };
  },
  mounted: function mounted() {
    this.isLogin = account.isLogined();
  },
  methods: {
    // 返回首页
    toHome: function toHome() {
      this.$router.replace({
        path: "/"
      });
    },
    // 返回我的订单
    toMyOrder: function toMyOrder() {
      this.$router.replace({
        name: "order",
        params: {
          status: "0"
        }
      });
    },
    topay: function topay() {
      this.$router.replace({
        name: "payMent",
        params: {
          orderId: this.orderId
        }
      });
    }
  }
};